import React from "react";

import Main from "./Main";

const App = () => (
  <div className="h-screen w-screen flex flex-col justify-end sm:justify-center items-center bg-gray-100">
    <div className="overflow-hidden rounded-lg mx-6 mb-8 md:mb-0">
      <img
        className="hidden md:h-20 md:block"
        alt="trtle upsell for shopify"
        src="/assets/desktop.png"
      />
      <img
        className="md:hidden"
        alt="trtle upsell for shopify"
        src="/assets/mobile.png"
      />
    </div>
    <Main />
  </div>
);

export default App;
